@import "../../../scss/main.scss";

.loginPage {
  background: var(--background-color);
  height: 100vh;
  display: flex;
  align-items: center;

  :global(.ant-result) {
    padding: 0 !important;
  }

  .container {
    padding-bottom: 10%;
    max-width: 700px;
    .logo {
      > svg {
        overflow: hidden;
        width: 110px;
        height: auto;
        padding-bottom: 20px;
      }
    }

    .card {
      text-align: center;
      width: 100%;
      max-width: 350px;
    }
  }
}
