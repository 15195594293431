.setPeriodModal {
  :global(.ant-input-number-lg) {
    width: 110px;
  }

  .period {
    font-weight: 700;
  }
}


