.landingPage {
  padding: 30px;
  .welcomeText {
    width: 100%;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;
  }

  .content {
    height: 100%;
    overflow: auto;
    margin-bottom: 20px;
  }
}
