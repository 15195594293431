
.roomList {
  :global(.anticon) {
    vertical-align: 2px !important;
  }

  :global(.ant-table-body), :global(.ant-table-content)  {
    overflow: auto !important;
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
  }

  .addCandidateSelect {
    width: 250px;
  }
}

.setPeriodModal {
  :global(.ant-input-number-lg) {
    width: 110px;
  }

  .period {
    font-weight: 700;
  }
}
