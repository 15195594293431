.pdfViewer {
  position: relative;
  overflow: hidden;
  background-color: var(--background-color);
  .controls {
    transition: all ease-in-out 200ms;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--pdf-header-bg);
    height: 40px;
    color: white;
    font-weight: 700;
    &.showControls {
      top: -40px;
    }
  }
}

.scrollbar {
  z-index: 2;
}

.toolbar {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  user-select: none;
  .toolbarIcon {
    cursor: pointer;
    font-size: 18px;
    margin: 0 10px;
  }
}
