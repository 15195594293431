.table {
  :global(.anticon) {
    vertical-align: 3px;
  }

  .ellipsisIcon {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
  }
}
