// because the icon and the message are not aligned
.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.anticon {
  vertical-align: 0;
}

.ant-btn .anticon {
  vertical-align: 2px !important;
  font-size: 12px !important;
  span {
    margin-left: 5px;
  }
}

.ant-modal-body {
  padding: 10px 24px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0;
}
