@import "src/scss/mixins/resolutionMixins";
.header {
  width: inherit;
  max-width: inherit;
  height: 50px;

  @include for-size(phone-only) {
    padding: 0 10px !important;
  }

  .avatar {
    transition: all ease-in-out 150ms;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: var(--primary-color);
    }

    .downIcon {
      font-size: 11px;
      margin-top: 3px;
    }
  }

  .logo {
    > svg {
      overflow: hidden;
      width: 40px;
    }
  }
}
