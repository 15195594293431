.react-pdf {
  &__Document {
    text-align: center;
    z-index: 0;
  }

  &__Page {
    margin-bottom: 1rem;
    //margin-top: 50px;
    &__canvas {
      //max-width: 90vw;
      height: auto !important;
      //margin-top: 150px !important;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
      background-color: rgba(29, 29, 29, 0.4);
    }
    &__canvas:first-child {
      margin-top: 20px !important;
    }
  }
}
