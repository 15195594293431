.candidateList {
  :global(.anticon) {
    vertical-align: 2px !important;
  }

  :global(.ant-table-body),
  :global(.ant-table-content) {
    overflow: auto !important;
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
  }

  .addCandidateSelect {
    width: 250px;
  }

  .createCandidateBtn {
    margin-left: 8px !important;
  }
}

.setPeriodModal {
  :global(.ant-input-number-lg) {
    width: 110px;
  }

  .period {
    font-weight: 700;
  }
}

.tags {
  :global(.ant-tag) {
    margin-right: 2px;
    margin-bottom: 2px;
  }
}
