.pageBase {
  background-color: var(--background-color);
  color: var(--text-color);
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  justify-content: center;
  display: flex;
  .divContent {
    max-width: 1200px;
    .content {
      display: flex;
      width: 100%;
      flex-direction: row;
      height: calc(100% - 55px);
      :global(.ant-card-body) {
        height: 100%;
        width: 100%;
        padding: 10px;
      }
    }
  }
}
