//Libraries
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "~antd/dist/antd.css";

//Ant Design Override
@import "./antDesignOverrides";

//Font
@import "font/font";

//Themes
@import "themes/defaultLight";

//Utils
@import "./scrollbar.scss";
